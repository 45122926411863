import React from "react"
import "./styles.scss"

import { 
  AiAnimation,
  TechnologyAnimation,
  BigDataAnimation,
  CloudComputingAnimation,
  EnterpriseProductAnimation,
  PolicyManagementAnimation
} from "src/components/atoms/animations"

import { ServiceBlock } from "src/components/organisms"

const serviceBlockData = [
  {
    title: "Artificial Intelligence",
    description: `Leveraging machine learning (ML) and deploying AI technologies to streamline and automate 
    business processes, boost efficiency, and reduce costs for our clients in both the Public Sector and 
    commercial sectors.`,
    anim: <AiAnimation />
  },
  {
    title: "Technology Advisory & Consulting",
    description: `Delivering a client-driven and customer-centered service with a suite of digital transformation 
    advisory, support, and consulting services; including world-class technical expertise and alignment with 
    changing technology trends and industry best practices.`,
    anim: <TechnologyAnimation />
  },
  {
    title: "Big Data & Analytics",
    description: `Making decisions easier by unifying our client's data to build modern cloud-based big 
    data environments. We use our specialized experience in data integration, automation, and data visualization 
    to enable your organization to be more responsive to an ever changing business environment.`,
    anim: <BigDataAnimation />
  },
  {
    title: "Cloud Computing",
    description: `Delivering cost-effective solutions to increase our client's IT capacity and functionality 
    through cloud-enabled data backup, disaster recovery, virtual desktops, software development and testing. 
    Our team has expertise in AWS, Azure and Google Cloud environments.`,
    anim: <CloudComputingAnimation />
  },
  {
    title: "Enterprise Product Development",
    description: `Boosting organizational productivity by creating customized tools, easy-to-use business 
    products, and software that streamline enterprise-wide processes and bridge siloed data.  We offer deep 
    industry knowledge and technical capabilities in business process automation, Agile Implementations, 
    Artificial Intelligence and Machine Learning, and Full Stack software development capabilities.`,
    anim: <EnterpriseProductAnimation />
  },
  {
    title: "Policy Management",
    description: `Modernizing policy management and compliance practices through an AI cloud-based platform.  
    Designed for enhanced efficiency, our solution is built on an industry-approved governance framework to 
    streamline the policy creation, revision, approval, and implementation processes.`,
    anim: <PolicyManagementAnimation />
  }
]

const Services = () => {

  return (
    <section id="services" className="services">
      <div className="content-wrap">
        <h5>Services</h5>
        <h2>Your Digital Transformation Starts Here</h2>
        <h4>In today’s world, most organizations are overwhelmed by an abundance of complex data.  Our team is confident that our knowledge and understanding of the ecosystem of modern technologies can lead you through an impactful digital transformation.  Our clients trust us, and you’ll see why!</h4>

        <div className="service-blocks">
          {serviceBlockData.map(block => <ServiceBlock title={block.title} description={block.description} animation={block.anim} />)}
        </div>
      </div>
    </section>
  )
}

export default Services