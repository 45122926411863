import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import "./styles.scss"

import { HeaderAnimation } from "src/components/atoms/animations"
import { Button, Link, ScrollIcon } from "src/components/atoms"

const Header = () => {
  const images = useStaticQuery(graphql`
    query {
      sphere: file(relativePath: { eq: "sphere.png" }) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  
  return (
    <header className="header">
      <div className="content-wrap">
        <div className="text-content">
          <h1>Leading Change Through Innovative Technology </h1>
          <h4>RDW Technologies helps organizations revolutionize their digital landscape through our data-driven solutions, easy-to-use tools, and services that are engineered  to help the world connect, grow and advance.</h4>
          <Button to={"mailto:info@rdwadvisorsllc.com"} hollow>Get in Touch</Button>
        </div>
        <div className="animation">
          <Img className="sphere" fluid={images.sphere.childImageSharp.fluid} />
          <HeaderAnimation />
        </div>
      </div>
      <Link to="#!/services" className="scroll-next">
        <ScrollIcon />
      </Link>
    </header>
  )
}

export default Header 