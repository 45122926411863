import React from "react" 
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import "./styles.scss"

const Callout = () => {

  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "cta.png" }) {
        childImageSharp {
          fluid(maxWidth: 1774) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="callout">
      <Img fluid={data.img.childImageSharp.fluid} alt="Moving the needle forward every day to transform Organizations with data-driven innovation." />
    </section>
  )
}

export default Callout