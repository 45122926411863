import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import "./styles.scss"

import { Button } from "src/components/atoms"

const Ics = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "ics-logo.png" }) {
        childImageSharp {
          fixed(width: 192, height: 86) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      infographic: file(relativePath: { eq: "ics-infographic.png" }) {
        childImageSharp {
          fluid(maxWidth: 825) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="solutions" className="ics">
      <div className="section-title">
        <hr />
        <h5>Solutions</h5>
        <h2>Intelligent Collaborative Solutions</h2>
        <h4>A scalable, secure, AI-empowered deep collaboration, automation and task management platform to help Federal Agencies maximize the wealth of data that is traditionally siloed throughout the organization.</h4>
      </div>
      <div className="content-wrap">
        <div className="infographic">
          <Img fluid={data.infographic.childImageSharp.fluid} alt="Designed to facilitate collaboration by breaking down the traditional silos and provide a single acces to a consolidated platform that is scalable as requirements evolve." />
        </div>
        <div className="text-content">
          <Img fixed={data.logo.childImageSharp.fixed} alt="Intelligent Collaborative Solutions" />
          <h4>Organizations that can securely work collaboratively, share critical information, and solve problems work more efficiently and effectively.</h4>
          <Button to={"#"} hollow>
            Learn More
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Ics