import React from "react" 
import "./styles.scss"

import { Button } from "src/components/atoms"

const DigitalTransformation = () => {

  return (
    <section className="digital-transformation">
      <div className="content-wrap">
        <h5>Let us help you jump start your</h5>
        <h2>Digital Transformation!</h2>
        <Button to={"mailto:info@rdwadvisorsllc.com"} hollow>
          Contact Us
        </Button>
      </div>
    </section>
  )
}

export default DigitalTransformation