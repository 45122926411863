import * as React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Layout, Seo } from "src/components/molecules"
import { Navigation, MobileNavigation, Footer } from "src/components/organisms"
import { Header, Services, Callout, Ics, DigitalTransformation } from "src/components/templates/home"

import Bars from "src/assets/images/lines.png"


// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" description="RDW Technologies helps federal agencies revolutionize the digital federal landscape through our easy-to-use, data-driven solutions and services that are engineered to fit the needs of every government  agency." />
      <Navigation />
      
      
      <Img className="bg-image" fluid={data.bg.childImageSharp.fluid} />
      <img className="bars" src={Bars} alt="Decoration Lines" />
      <div className="content">
        <Header />
        <Services />
        <Callout />
        <Ics />
        <DigitalTransformation />
      </div>
      <Footer />

      <MobileNavigation />
    </Layout>
  )
}

export const query = graphql`
  query {
    bg: file(relativePath: { eq: "header-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1404) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bars: file(relativePath: { eq: "lines.png" }) {
      childImageSharp {
        fluid(maxWidth: 1186) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
